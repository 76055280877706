@import 'bootstrap/scss/button-group';

.btn-group-toggle {
  .btn {
    box-shadow: inset 0 0 0 1px $border-color;
    --#{$prefix}btn-disabled-color: #{$primary} !important;

    &:focus {
      box-shadow: inset 0 0 0 1px $border-color !important;
      border-color: red !important;
    }
    &:hover {
      --#{$prefix}btn-bg: #{get-interaction-color("hover", $primary)} !important;
    } 
    &.active, &.active:focus {
      box-shadow: inset 0 0 0 1px $primary !important;
      border-color: red !important;
    }
  }
  
}

.btn-group-toggle > .btn-check:checked + .btn {
  z-index: 2;
}

.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: calc(var(--#{$prefix}border-width) * -1);
}

// XL SIZE
.btn-group-xl .btn {
  min-height: $input-height-xl;

  @include button-size(
    $btn-padding-y-xl,
    $btn-padding-x-xl,
    $btn-font-size-xl,
    $btn-border-radius-xl
  );
}

.btn-group,
.btn-group-vertical {
  > [class^='btn-toggle'] {
    --#{$prefix}btn-border-color: #{$btn-default-border-color} !important;
    --#{$prefix}btn-color: #{$secondary} !important;
    --#{$prefix}btn-bg: transparent !important;
    --#{$prefix}btn-hover-bg:  #{get-interaction-color("hover", $primary)} !important;
    --#{$prefix}btn-hover-color: #{$primary} !important;
    --#{$prefix}btn-hover-border-color: #{$primary} !important;
    --#{$prefix}btn-active-bg: #{get-interaction-color('selected', $primary)} !important;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  @each $color, $value in $theme-colors {
    .btn-check:focus + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {   
      box-shadow: inset 0 0 0 1px #{$value} !important;
    }

    .btn-check:focus-visible + .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      box-shadow: inset 0 0 0 0.125rem #{$value} !important;
      --#{$prefix}btn-bg: #{get-interaction-color("hover", $value)} !important;
    }

    .btn-toggle-#{$color}:not([disabled]):not(.disabled) {
      --#{$prefix}btn-color: #{$secondary} !important;
      --#{$prefix}btn-active-color: #{$value} !important;
      --#{$prefix}btn-active-border-color: #{$value} !important;
      --#{$prefix}btn-hover-color: #{$value} !important;
      --#{$prefix}btn-border-color: #{$value} !important;
      --#{$prefix}btn-hover-border-color: #{$value} !important;
      --#{$prefix}btn-hover-bg:  #{get-interaction-color("hover", $value)} !important;
      --#{$prefix}btn-active-bg: #{get-interaction-color('selected', $value)} !important;
      --#{$prefix}btn-active-shadow: inset 0 0 0 1px #{$value} !important;

      &:hover {
        --#{$prefix}btn-color: #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color("hover", $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color("selected_hover", $value)} !important;
      }

      &:focus, &:active {
        --#{$prefix}btn-color: #{$value} !important;
        box-shadow: inset 0 0 0 1px #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color("selected", $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color("selected_onclick", $value)} !important;
        z-index: 2;
      }

      &.active {
        --#{$prefix}btn-color: #{$value} !important;
        box-shadow: inset 0 0 0 1px #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color("selected", $value)} !important;
        --#{$prefix}btn-active-bg: #{get-interaction-color("selected", $value)} !important;
      }

      &:focus-visible, &:focus-within {
        box-shadow: inset 0 0 0 0.125rem #{$value} !important;
        --#{$prefix}btn-bg: #{get-interaction-color("hover", $value)} !important;
      }
    }
  }

  .btn.btn-xl {
    .icon {
      font-size: $btn-icon-size-xl;
      line-height: 1;
    }
  }

  .btn.btn-lg {
    .icon {
      font-size: $btn-icon-size-lg;
      line-height: 1;
    }
  }

  .btn.btn-sm {
    .icon {
      font-size: $btn-icon-size-sm;
      line-height: 1;
    }
  }
}
