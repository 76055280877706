@import 'bootstrap/scss/nav';

$nav-pills-active-border-width: 3px !default;
$nav-link-active-color: $primary !default;

$nav-column-gap-sm: 1rem;
$nav-column-gap: 1.25rem;
$nav-column-gap-lg: 1.5rem;
$nav-column-gap-xl: 2rem;

.nav {
  &:not(.nav-tabs){
    column-gap: $nav-column-gap;
    .nav-link {
      --#{$prefix}nav-link-padding-x: 0;
      --#{$prefix}nav-link-padding-y: #{px-to-rem(6)};
    }
  }
}
.nav-link {
  line-height: 1.25rem;
}

.nav-link.active:not(.disabled) {
  --#{$prefix}nav-link-color: #{$nav-link-active-color};
}

.nav-tabs {
  --#{$prefix}nav-tabs-link-hover-border-color: transparent !important;
  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        --#{$prefix}nav-tabs-link-active-border-color: #{$value};
        border-bottom-color: transparent !important;
      }
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-y: #{px-to-rem(5)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(10)};
  }
}

// SIZING
.nav-sm {
  &:not(.nav-tabs){
    column-gap: $nav-column-gap-sm;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(2)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(1)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(1)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{$font-size-sm};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(8)};
    line-height: px-to-rem(16);
  }
}
.nav-lg {
  &:not(.nav-tabs){
    column-gap: $nav-column-gap-lg;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(10)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(9)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(8.5)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: #{px-to-rem(12)};
  }
}
.nav-xl {
  &:not(.nav-tabs){
    column-gap: $nav-column-gap-xl;
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(12)};
    }
  }
  &.nav-tabs {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(11)};
    }
  }
  &.nav-underline {
    .nav-link {
      --#{$prefix}nav-link-padding-y: #{px-to-rem(10.5)} !important;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-font-size: #{px-to-rem(16)};
    --#{$prefix}nav-link-padding-x: #{px-to-rem(16)};
    line-height: px-to-rem(24);
  }
}

// UNDERLINE
.nav-underline {
  --#{$prefix}nav-underline-link-active-color: #{$nav-link-active-color};
  --#{$prefix}nav-underline-border-width: #{$nav-pills-active-border-width};
  --#{$prefix}nav-underline-gap: 0;

  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        border-bottom-color: $value;
      }
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-y: #{px-to-rem(4.5)} !important;
  }
  .nav-link.active,
  .show > .nav-link {
    font-weight: 500;
  }
  .nav-link:not(.active) {
    &:hover, &:focus {
      border-bottom-color: transparent;
    }
  }
  &.nav-sm {
    $nav-pills-active-border-width: 2px;
    --#{$prefix}nav-underline-border-width: #{$nav-pills-active-border-width};
  }


  // VERTICAL
  &.flex-column {
    .nav-link {
      border-bottom-width: 0;
      // Ensure to use a standard y spacing
      padding-left: map-get($spacers, 3);
      &:not([disabled]):not(.disabled).active {
        background-color: get-interaction-color("selected");
        box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
      }
    }
  
    // Sizing
    &.nav-sm {
      .nav-link {
        padding-left: map-get($spacers, 2);
        &:not([disabled]):not(.disabled).active {
          $nav-pills-active-border-width: 2px;
          box-shadow: inset $nav-pills-active-border-width 0 0 0 $nav-link-active-color;
        }
      }
    }
    &.nav-lg {
      .nav-link {
        padding-left: 1.25rem;
      }
    }
    &.nav-xl {
      .nav-link {
        padding-left: map-get($spacers, 4);
      }
    }
  
    // Nested vertical navs
    .nav-underline .flex-column {
      border-left: 1px solid $border-color;
      margin: map-get($spacers, 2) 0 map-get($spacers, 2) map-get($spacers, 4);
  
      .nav-link.active {
        background-color: transparent !important;
        border-left: none;
        color: map-get($theme-colors, 'info');
        font-weight: $font-weight-semibold;
        padding-left: calc(#{map-get($spacers, 4)} - 3px);
      }
    }
  }
}

//
// Nav hover
//

.nav-hover:not(.nav-tabs) {
  column-gap: unset !important;
  &.nav-sm {
    .nav-link {
      --#{$prefix}nav-link-padding-x: .5rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
      }
    }
  }
  &.nav-lg {
    .nav-link {
      --#{$prefix}nav-link-padding-x: .75rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: .75rem;
        padding-left: .75rem;
      }
    }
  }
  &.nav-xl {
    .nav-link {
      --#{$prefix}nav-link-padding-x: 1rem !important;
    }
    &.nav-underline {
      .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
  }
  &.nav-underline {
    .nav-link {
      padding-right: .625rem;
      padding-left: .625rem;
    }
  }
  .nav-link {
    --#{$prefix}nav-link-padding-x: .625rem !important;
    &:not([disabled]):not(.disabled) {
      &.active {
        background-color: get-interaction-color("selected");
      }
      &:hover, &:focus, &.focus {
        background-color: get-interaction-color("hover");
      }
      &:active {
        background-color: get-interaction-color("onclick");
      }
    }
  }
}

