@use "sass:math";

@import 'bootstrap/scss/dropdown';

$dropdown-link-padding-x-sm: px-to-rem(8) !default;
$dropdown-link-padding-y-sm: 0.469rem !default;
$dropdown-header-spacing-top-sm: px-to-rem(8) !default;
$dropdown-header-spacing-bottom-sm: px-to-rem(4) !default;
$dropdown-header-font-size-sm: px-to-rem(10) !default;
$dropdown-icon-size-sm: px-to-rem(18) !default;

$dropdown-link-padding-x-lg: px-to-rem(20) !default;
$dropdown-link-padding-y-lg: 0.875rem !default;
$dropdown-header-spacing-top-lg: px-to-rem(20) !default;
$dropdown-header-spacing-bottom-lg: px-to-rem(8) !default;
$dropdown-header-font-size-lg: px-to-rem(12) !default;
$dropdown-icon-size-lg: px-to-rem(22) !default;

$dropdown-link-padding-x-xl: px-to-rem(24) !default;
$dropdown-link-padding-y-xl: 0.907rem !default;
$dropdown-header-spacing-top-xl: px-to-rem(24) !default;
$dropdown-header-spacing-bottom-xl: px-to-rem(16) !default;
$dropdown-header-font-size-xl: px-to-rem(14) !default;
$dropdown-icon-size-xl: px-to-rem(24) !default;

$dropdown-header-spacing-top: px-to-rem(16) !default;
$dropdown-header-spacing-bottom: px-to-rem(8) !default;
$dropdown-icon-size: px-to-rem(20) !default;

$btn-caret-down-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5z' fill='currentColor'/></svg>");
$btn-caret-up-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 14l5-5 5 5z' fill='currentColor'/></svg>");
$btn-caret-right-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M10 17l5-5-5-5v10z' fill='currentColor'/><path d='M0 24V0h24v24H0z' fill='none'/></svg>");
$btn-caret-left-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M14 7l-5 5 5 5V7z' fill='currentColor'/><path d='M24 0v24H0V0h24z' fill='none'/></svg>");

.dropdown-menu {
  .dropdown-header {
    padding: $dropdown-header-padding-x $dropdown-header-padding-x
      math.div($dropdown-header-padding-x, 2) $dropdown-header-padding-x;
  }
}

.dropdown-item {
  font-weight: $font-weight-medium;

  & + .dropdown-item {
    border-top: 1px solid rgba($border-color, 0.2);
  }

  &:active:not(.active) {
    color: $dropdown-link-color;
  }

  &.active {
    background-color: get-interaction-color("selected") !important;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: get-interaction-color("selected_hover") !important;
    }

    &:active {
      background-color: get-interaction-color("selected_onclick") !important;
    }

    &::after {
      display: inline-block;
      vertical-align: middle;
      content: '';
      width: $dropdown-icon-size;
      height: $dropdown-icon-size;
      margin-left: $dropdown-item-padding-x;
      background: transparent
        str-replace(
          url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 26 26' %3e%3cpath fill='#{$dropdown-link-active-color}' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3e%3c/svg%3e"),
          '#',
          '%23'
        )
        no-repeat center;
      background-size: cover;
    }
  }
}

.btn-group {
  // XL
  .btn-xl + .dropdown-menu {
    font-size: $btn-font-size-xl;

    .dropdown-item {
      padding: $dropdown-link-padding-y-xl $btn-padding-x-xl;

      &.active {
        &.active::after {
          width: $dropdown-icon-size-xl;
          height: $dropdown-icon-size-xl;
          margin-left: $btn-padding-x-xl;
        }
      }
    }

    .dropdown-header {
      padding: $dropdown-header-spacing-top-xl $btn-padding-x-xl
        $dropdown-header-spacing-bottom-xl;
      font-size: $dropdown-header-font-size-xl;
    }
  }

  // LG
  .btn-lg + .dropdown-menu {
    font-size: $btn-font-size-lg;

    .dropdown-item {
      padding: $dropdown-link-padding-y-lg $btn-padding-x-lg;

      &.active::after {
        width: $dropdown-icon-size-lg;
        height: $dropdown-icon-size-lg;
        margin-left: $btn-padding-x-lg;
      }
    }

    .dropdown-header {
      padding: $dropdown-header-spacing-top-lg $btn-padding-x-lg
        $dropdown-header-spacing-bottom-lg;
      font-size: $dropdown-header-font-size-lg;
    }
  }

  // SM
  .btn-sm + .dropdown-menu {
    font-size: $btn-font-size-sm;

    .dropdown-item {
      padding: $dropdown-link-padding-y-sm $btn-padding-x-sm;

      &.active {
        &.active::after {
          width: $dropdown-icon-size-sm;
          height: $dropdown-icon-size-sm;
          margin-left: $btn-padding-x-sm;
        }
      }
    }

    .dropdown-header {
      padding: $dropdown-header-spacing-top-sm $btn-padding-x-sm
        $dropdown-header-spacing-bottom-sm;
      font-size: $dropdown-header-font-size-sm;
    }
  }
}

// Toggle split
//
.dropdown-toggle-split {
  padding-right: $btn-padding-x;
  padding-left: $btn-padding-x;

  &.btn-sm {
    padding-right: $btn-padding-x-sm;
    padding-left: $btn-padding-x-sm;
  }

  &.btn-lg {
    padding-right: $btn-padding-x-lg;
    padding-left: $btn-padding-x-lg;
  }

  &.btn-xl {
    padding-right: $btn-padding-x-xl * 0.7;
    padding-left: $btn-padding-x-xl * 0.7;
  }
}

.btn-sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm;
  padding-left: $btn-padding-x-sm;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg;
  padding-left: $btn-padding-x-lg;
}

.btn-xl + .dropdown-toggle-split {
  padding-right: $btn-padding-x-xl * 0.7;
  padding-left: $btn-padding-x-xl * 0.7;
}

.dropup {
  .dropdown-toggle::after {
    mask-image: $btn-caret-up-icon-bg;
    vertical-align: middle;
    border: 0;
  }
}

.dropright {
  .dropdown-toggle::after {
    mask-image: $btn-caret-right-icon-bg;
  }
}

.dropleft {
  .dropdown-toggle {
    &:not(.dropdown-toggle-split) {
      &::after {
        display: none;
      }
      &::before {
        content: "";
        background-color: $primary-alt;
        mask-image: $btn-caret-left-icon-bg;
      }
    }
    &.dropdown-toggle-split::after {
      mask-image: $btn-caret-left-icon-bg;
    }
  }
}
// Caret
//

@mixin btn-caret-sizing($padding-y, $padding-x, $icon-size) {
  &::after, &::before {
    background-color: $primary-alt;
    mask-image: $btn-caret-left-icon-bg;
    background-size: $icon-size;
    background-repeat: no-repeat;
    background-color: transparent;
    margin-top: calc(-1 * $btn-border-width);
    line-height: 1;
    border: 0;
    width: $icon-size;
    height: $icon-size;
  }
  &::after {
    background-color: $primary-alt;
    mask-image: $btn-caret-down-icon-bg;
    -webkit-mask-image: $btn-caret-down-icon-bg;
  }
}

.dropdown-toggle::after {
  vertical-align: middle;
}

.dropdown-toggle {
  @include btn-caret-sizing($btn-padding-y, $btn-padding-x, $btn-icon-size);
  box-shadow: unset !important;

  &.btn-xl {
    @include btn-caret-sizing(
      $btn-padding-y-xl,
      $btn-padding-x-xl,
      $btn-icon-size-xl
    );
  }

  &.btn-lg {
    @include btn-caret-sizing(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $btn-icon-size-lg
    );
  }

  &.btn-sm {
    @include btn-caret-sizing(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $btn-icon-size-sm
    );
  }
}

.btn-group .btn {
  &.dropdown-toggle {
    &:hover, &:focus {
      box-shadow: unset !important;
    }
  }
}

//
// EXCEPTIONS
//

// BTN LINK
.btn-link {
  &.dropdown-toggle {
    --#{$prefix}btn-active-color: $secondary !important;
    &::after, &::before {
      background-color: $link-color;
   }
  }
}

// BTN LIGHT, BTN WHITE
.btn-light, .btn-white {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: #000 !important;
   }
  }
}

// BTN BLACK, BTN DARK
.btn-dark, .btn-black {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: #fff !important;
   }
  }
}

// BTN PRIMARY-ALT, BTN DEFAULT
.btn-primary-alt, .btn-default {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: $primary !important;
   }
  }
}

.btn-link:hover:not([disabled]):not(.disabled), .btn-link:focus:not([disabled]):not(.disabled) {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: $primary;
    }
  }
}

// NAV LINK
.nav-link {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: $secondary;
   }
  }
}

.nav-link:hover:not([disabled]):not(.disabled), .btn-link:focus:not([disabled]):not(.disabled) {
  &.dropdown-toggle {
    &::after, &::before {
      background-color: $primary;
   }
  }
}

// BTN DISCREET, OUTLINE AND FLAT
@each $color, $value in $theme-colors {
  .btn-outline-#{$color}, .btn-discreet-#{$color}, .btn-flat-#{$color} {
    &.dropdown-toggle {
      &::after, &::before {
        background-color: $value
     }
    }
  }
  .btn-outline-#{$color} {
    &.dropdown-toggle {
      box-shadow: inset 0 0 0 1px $value !important;
    }
  }
  .btn-outline-#{$color}:hover:not([disabled]):not(.disabled), .btn-discreet-#{$color}:hover:not([disabled]):not(.disabled),
  .btn-outline-#{$color}:focus:not([disabled]):not(.disabled), .btn-discreet-#{$color}:focus:not([disabled]):not(.disabled) {
    &.dropdown-toggle {
      &::after, &::before {
        background-color: $primary-alt;
      }
    }
  }
}
